// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
var Trix = require("trix")
var numeral = require("numeral")
require("@rails/actiontext")
require("jquery")
require("sortablejs")
require.context('./images', true)
import "./richtext"
import Foundation from 'foundation-sites';
Foundation.addToJquery(jQuery);
global.trix = Trix;
import Sortable from 'sortablejs';
global.Sortable = Sortable;

import {html, render} from 'lit-html';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';
global.html = html;
global.render = render;
global.unsafeHTML = unsafeHTML;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function calculate_prices() {
  if ($("[data-price]").length == 0) {
    return;
  }
  var subtotal = 0;
  var total = $("[data-price]").map(function(){
    var self = $(this);
    var price = parseFloat(self.data("price"));
    let calculated_price = 0;
    if (self.is("input")) {
      calculated_price = self.val() * price;
    } else {
      calculated_price = price;
    }
    if (self.data("target")) {
      $("#" + self.data("target")).html("$" + numeral(calculated_price).format("0.00"))
    }
    if (self.data("subtotal")) {
      subtotal += calculated_price;
    }
    return calculated_price;
  }).get().reduce(function(acc, price) {
    return acc + price;
  }, 0);

  $("#total").html("$" + numeral(total).format("0.00"));
  $("#subtotal").html("$" + numeral(subtotal).format("0.00"));
}

$(function(){
  $(document).on('turbolinks:load', function() {
    $(document).foundation();
    calculate_prices();
  });

  $(document).on("change", "input[data-price]", calculate_prices);
  calculate_prices();
});
